
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_reviews(params)  {
            return await axios.post('reviews/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_reviews()  {
            // return await axios.get('reviews/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_reviews')
        },
        async delete_reviews(review_id)  {
            return await axios.post('reviews/delete.php' , {
                review_id : review_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(review_id)  {
            return await axios.post('reviews/readOne.php' , {
                review_id : review_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_reviews(params)  {
            return await axios.post('reviews/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_reviews(params)  {
            return await axios.post('reviews/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
